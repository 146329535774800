.footer {
    color: $white;
    font-family: $content;
    margin: 0px 15px;
    position: absolute;
    bottom: 15px;
    right: 0px;
    text-align: right;

    span {
        cursor: pointer;
        color: $light; } }
