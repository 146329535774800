.section {
    background-color: rgba($white, 0.8);
    width: 518px;
    max-width: calc(100% - 30px);
    margin: 15px;
    display: inline-block;
    margin-bottom: 65px;

    .heading {
        background-color: transparent;
        color: black;
        font-size: 22px;
        font-family: $content;
        padding: 20px 0px 0px 0px;
        margin-bottom: 5px; }

    .sub_heading {
        font-family: $content;
        font-size: 18px;
        margin: 25px 0px 15px 0px; }

    textarea {
        width: calc(100% - 53px);
        border-radius: 0px;
        border: 1px solid rgb(133, 133, 133);
        outline: none;
        height: 100px;
        font-size: 16px;
        margin: 0px 0px 15px 0px;
        font-family: $content;
        padding: 10px; }

    table {
        width: calc(100% - 30px);
        margin: 0px 15px;

        tbody {
            tr {
                height: 70px;
                border-bottom: 1px solid black;

                td {
                    &:nth-child(2) {
                        text-align: right; } } } } }

    hr {
        height: 0.5px;
        background-color: rgb(133, 133, 133);
        border: none;
        width: calc(100% - 36px);
        margin: 0px 18px; }

    .contact {
        font-family: $content;
        margin: 40px 0px;
        max-width: 100%;

        td {
            text-align: left; }

        .contact_name {
            color: black;
            font-size: 18px; }

        .contact_reason {
            color: $dark; }

        .contact_number {
            display: inline-block;
            vertical-align: middle;
            font-size: 22px; } }

    .button {
        background-color: $dark;
        color: $white;
        border-radius: 0px;
        border: none;
        outline: none;
        padding: 10px;
        font-size: 22px;
        width: 512px;
        max-width: calc(100% - 30px);
        margin: 15px;
        margin-top: 0px;
        cursor: pointer; } }
