.heading {
    padding: 15px;
    padding-bottom: 60px;
    // background-color: $dark

    .burger {
        color: $white;
        float: right;
        font-size: 40px;
        display: none; }

    .logo {
        color: $white;
        font-family: $heading;
        font-size: 40px;
        display: inline-block;
        cursor: pointer;

        .ball {
            width: 15px;
            margin: 0px 5px -2px 5px; } }

    .links {
        float: right;
        display: inline-block;

        .link {
            margin: 0px 15px;
            display: inline-block;
            color: $white;
            font-family: $heading;
            font-size: 40px;
            cursor: pointer;
            text-transform: lowercase;

            &.active {
                text-decoration: underline;
                text-underline-offset: 5px; }

            &:nth-last-child(1) {
                background-color: $white;
                color: $dark;
                padding: 0px 15px 10px 15px;
                border-radius: 20px; } } } }

@media screen and ( max-width: 999px ) {
    .heading {
        .links {
            display: none; }

        .burger {
            display: block; } } }


@media screen and ( max-width: 860px ) {
    .heading {
        .logo {
            font-size: 30px; } } }

@media screen and ( max-width: 576px ) {
    .heading {
        padding-bottom: 20px; } }

