.menu {
	position: fixed;
	transform: translate(-50%, calc(-50% - 1px));
	top: 50%;
	left: 50%;
	background: rgba(black, 0.8);
	width: calc(100vw + 1px);
	height: calc(100vh + 1px);
	height: -webkit-fill-available;
	// display: none
	visibility: hidden;
	z-index: 102; }

.auto_menu {
	display: block; }

.menuContent {
	transition: 0.2s transform ease-in-out;
	position: fixed;
	transform: translate(100%, 0%);
	// top: 0px
	bottom: -1px;
	right: 0px;
	background: $white;
	/* border: 2px solid black */
	width: 300px;
	max-width: calc(100% - 20px);
	padding: 10px;
	padding-bottom: 0px;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	font-family: $content;
	text-align: center;
	// border-top-left-radius: 20px
	// border-top-right-radius: 20px
	overflow: hidden;

	.menuCenter {
		max-height: calc(90vh - 30px);
		width: calc(100% + 20px);
		margin-left: -10px;
		overflow-y: auto;
		overflow-y: overlay;
		transform: translate(0px, 0px);
		text-align: left;

		hr {
			margin: 45px 18px 41px 18px;
			border: none;
			background-color: rgba(black, 0.1);
			height: 0.5px; }

		.link {
			font-family: $heading;
			font-size: 50px;
			text-align: left;
			margin: 15px 15px;
			color: $dark;
			cursor: pointer;
			text-transform: lowercase;

			&.active {
				text-decoration: underline;
				text-underline-offset: 5px; }

			&:nth-last-child(2) {
				background-color: $dark;
				color: $white;
				display: inline-block;
				padding: 0px 15px 10px 15px;
				border-radius: 20px; } } } }

.menu_x {
	// position: absolute
	// width: 100%
	text-align: left;
	// margin-top: -10px
	margin-bottom: -40px;
	font-size: 50px;
	margin-right: -10px;
	margin-left: -10px;
	cursor: pointer;
	color: black;
	// border-top-left-radius: 20px
	// border-top-right-radius: 20px
	background-color: $white;
	line-height: 30px;
	// border-top: 10px solid $primary
	overflow: hidden; }

.spacing {
	height: 40px; }

.spacing-2 {
	height: 20px; }

.menu_button {
	display: inline-block;
	width: 100%; }
