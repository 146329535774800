$dark: #115ba0;
$light: #ccff00;
$white: #ffffff;

// d0ff00
// c5e04d

$line-width: 5;

$heading: 'Zen Tokyo Zoo', cursive;
$content: Arial, Helvetica, sans-serif;
