.tennis_court {
    background-color: $light;
    width: 100%;
    height: 1000px;
    text-align: center;

    .outside_box {
        background-color: $dark;
        border:  $line-width + px solid $white;
        height: 100%;
        width: calc(100% - 10px);

        .inside_box {
            border: $line-width + px solid $white;
            height: 100%;
            width: calc(80% - 10px);
            margin: (-$line-width + px);
            display: inline-block;
            position: relative;

            .service {
                width: calc(100% + 10px);
                margin: 0px -5px;
                height: 100%;

                .service_box {
                    border: $line-width + px solid $white;
                    display: inline-block;
                    width: calc(50% - 8px);
                    height: 50%;
                    margin: (-$line-width + px);
                    margin-right: 0px;
                    vertical-align: middle;
                    line-height: 100%;
                    transform: translate(0px, 50%);

                    &:nth-child(2) {
                        margin-right: -5px; } } }

            .center_line {
                height: $line-width + px;
                width: 100%;
                background-color: $white;
                position: absolute;
                top: 50%; } } } }
