.booking {
    background-color: rgba($white, 0.8);
    width: 518px;
    max-width: calc(100% - 30px);
    margin: 15px;
    display: inline-block;
    margin-bottom: 65px;

    .heading {
        background-color: transparent;
        color: black;
        font-size: 22px;
        font-family: $content;
        padding: 20px 0px 0px 0px;
        margin-bottom: 15px; }

    .name_input {
        border-radius: 0px;
        border: 1px solid rgb(133, 133, 133);
        outline: none;
        padding: 10px;
        font-size: 22px;
        background-color: $white;
        width: calc(100% - 52px); }

    .column {
        display: inline-block;
        vertical-align: middle;
        margin: 15px;
        font-family: $content;
        max-width: calc(100% - 30px);
        text-align: center;

        .column_entry {
            display: block;
            margin: 15px 0px; }

        &:nth-child(3) {
            text-align: left; }

        .time_select, .duration_select {
            border-radius: 0px;
            outline: none;
            padding: 5px;
            font-size: 22px;
            background-color: $white;
            width: 100%; }

        label {
            font-size: 22px; }

        .sub_heading {
            margin-bottom: 5px;
            color: $dark; }

        .react-calendar {
            .react-calendar__tile--now {
                background-color: $light; }

            .react-calendar__tile--active {
                background-color: $dark; }

            abbr {
                text-decoration: none; } } }

    .booking_button {
        background-color: $dark;
        color: $white;
        border-radius: 0px;
        border: none;
        outline: none;
        padding: 10px;
        font-size: 22px;
        width: 512px;
        max-width: calc(100% - 30px);
        margin: 15px;
        margin-top: 0px;
        cursor: pointer; } }

@media screen and ( max-width: 576px ) {
    .booking {
        .column {
            .column_entry {
                display: inline-block;
                margin: 0px 15px; } } } }
