.date_range {
    border: 1px solid black;
    width: 250px;
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 15px;

    .month {
        background-color: $light;
        color: black;
        padding: 10px;
        border-bottom: 1px solid black; }

    .day {
        color: black;
        font-size: 70px;

        .th {
            display: inline-block;
            vertical-align: top;
            font-size: 18px;
            padding-top: 12px; } }

    .time_range {
        border: 1px solid black;
        margin: -1px;
        position: relative;
        overflow: hidden;

        .first_time {
            display: inline-block;
            vertical-align: middle;
            background-color: $light;
            color: black;
            width: calc(50% - 20px);
            padding: 10px;
 }            // border: 1px solid black

        .arrow_time {
            background-color: $light;
            width: 30px;
            height: 30px;
            position: absolute;
            transform: translate(-50%, -50%) rotate(45deg);
            top: 50%;
            left: 50%;
            border-top: 1px solid black;
            border-right: 1px solid black; }

        .second_time {
            display: inline-block;
            vertical-align: middle;
            color: black;
            width: calc(50% - 20px);
            padding: 10px; } } }
